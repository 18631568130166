import DOMPurify from 'dompurify';
import Paragraph from 'components/TextComps/Paragraph';
import { ParameterModel } from 'types/trackmanParameters';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

type Props = {
  visibleParameter: ParameterModel;
};

export const UsageInfo = observer(({ visibleParameter }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='usage-info'>
      <Paragraph>
        <>{t('WhatIsItUsedFor', { ns: 'trackmanParameters' })}</>
      </Paragraph>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(visibleParameter.usageInfo) }} />
    </div>
  );
});

export default UsageInfo;
