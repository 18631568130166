import { Navigate, Route, Routes } from 'react-router-dom';

import AboutTab from 'pages/GetCertified/Tabs/AboutTab/AboutTab';
import ApplyTab from 'pages/GetCertified/Tabs/ApplyTab/ApplyTab';
import BillingAndPaymentTab from 'pages/Profile/Tabs/BillingAndPaymentTab/BillingAndPaymentTab';
import CertificateTab from 'pages/GetCertified/Tabs/CertificateTab/CertificateTab';
import ClassesTab from 'pages/Organization/Tabs/ClassesTab/ClassesTab';
import CookiePolicyPage from 'pages/Policies/CookiePolicyPage/CookiePolicyPage';
import CourseList from 'pages/Admin/Courses/CourseList/CourseList';
import EditCourse from 'pages/Admin/Courses/EditCourse/EditCourse';
import EditLearningMaterial from 'pages/Admin/LearningMaterials/EditLearningMaterial/EditLearningMaterial';
import EditLesson from 'pages/Admin/Lessons/EditLesson/EditLesson';
import EditOrganization from 'pages/Admin/Organizations/EditOrganization/EditOrganization';
import EditStudyCourse from 'pages/Admin/StudyCourses/EditStudyCourse/EditStudyCourse';
import EditStudyLesson from 'pages/Admin/StudyLessons/EditStudyLesson/EditStudyLesson';
import EditTrackmanParameter from 'pages/Admin/TrackmanParameters/EditTrackmanParameter/EditTrackmanParameter';
import ExcelCalculator from 'pages/Admin/ExcelCalculator/ExcelCalculator';
import Feedback from 'pages/Feedback/Feedback';
import GetCertified from 'pages/GetCertified/GetCertified';
import HomePage from 'pages/HomePage/HomePage';
import { LandingPage } from './pages/LandingPage/LandingPage';
import LearningMaterials from 'pages/LearningMaterials/LearningMaterials';
import LearningMaterialsLessonPage from 'pages/LearningMaterials/LearningMaterialsLessonPage/LearningMaterialsLessonPage';
import LearningMaterialsList from 'pages/Admin/LearningMaterials/LearningMaterialsList/LearningMaterialsList';
import LessonList from 'pages/Admin/Lessons/LessonList/LessonList';
import ManageUserData from 'pages/Admin/ManageUserData/ManageUserData';
import MastersTab from 'pages/GetCertified/Tabs/MastersTab/MastersTab';
import ModulesAndTests from 'pages/ModulesAndTests/ModulesAndTests';
import ModulesAndTestsItem from 'pages/ModulesAndTests/ModulesAndTestsItem/ModulesAndTestsItem';
import NoAccess from 'pages/ErrorPages/NoAccess/NoAccess';
import NotFound from 'pages/ErrorPages/NotFound/NotFound';
import Organization from 'pages/Organization/Organization';
import OrganizationsList from 'pages/Admin/Organizations/OrganizationsList/OrganizationsList';
import PartnersTab from 'pages/GetCertified/Tabs/PartnersTab/PartnersTab';
import PlayerInformationTab from 'pages/Profile/Tabs/PlayerInformationTab/PlayerInformationTab';
import PlayerSettingsTab from 'pages/Profile/Tabs/PlayerSettingsTab/PlayerSettingsTab';
import PrivacyPolicyPage from 'pages/Policies/PrivacyPolicyPage/PrivacyPolicyPage';
import Profile from 'pages/Profile/Profile';
import ProgressTab from 'pages/Organization/Tabs/ProgressTab/ProgressTab';
import PurchaseMembershipPage from 'pages/GoPremium/PurchaseMembershipPage/PurchaseMembershipPage';
import StudentsTab from 'pages/Organization/Tabs/StudentsTab/StudentsTab';
import StudyCoursesList from 'pages/Admin/StudyCourses/StudyCoursesList/StudyCoursesList';
import StudyLessonsList from 'pages/Admin/StudyLessons/StudyLessonsList/StudyLessonsList';
import SubscriptionsTab from 'pages/Profile/Tabs/SubscriptionsTab/SubscriptionsTab';
import TermsAndConditionsPage from 'pages/Policies/TermsAndConditionsPage/TermsAndConditionsPage';
import TestInvitations from 'pages/Admin/TestInvitations/TestInvitations';
import TrackmanParameters from 'pages/TrackmanParameters/TrackmanParameters';
import TrackmanParametersList from 'pages/Admin/TrackmanParameters/TrackmanParametersList/TrackmanParametersList';
import TranslatorPage from 'pages/Admin/TranslatorPage/TranslatorPage';

export const CertificationsTabsRelativeRoutes = {
  AboutTrackmanCertification: 'AboutTrackmanCertification',
  ApplyForMaster: 'ApplyForMaster',
  Certificate: 'Certificate',
  Partners: 'Partners',
  Masters: 'Masters',
} as const;

export const LearningMaterialsRelativeRoutes = {
  LearningMaterialsItem: ':courseId/:lessonId',
} as const;

export const ProfileTabsRelativeRoutes = {
  PlayerInformation: 'PlayerInformation',
  PlayerSettings: 'PlayerSettings',
  BillingAndPayment: 'BillingAndPayment',
  Subscriptions: 'Subscriptions',
} as const;

export const OrganizationTabsRelativeRoutes = {
  Progress: 'Progress',
  Students: 'Students',
  Classes: 'Classes',
} as const;

export const AdminPagesRelativeRoutes = {
  CourseList: 'Courses',
  CoursesEditor: 'CoursesEditor',
  EditCourseItem: 'CoursesEditor/:courseId',
  LessonList: 'Lessons',
  LessonsEditor: 'LessonsEditor',
  EditLessonItem: 'LessonsEditor/:lessonId',
  StudyCoursesList: 'StudyCourses',
  StudyCoursesEditor: 'StudyCoursesEditor',
  EditStudyCourseItem: 'StudyCoursesEditor/:studyCourseId',
  StudyLessonsList: 'StudyLessons',
  StudyLessonsEditor: 'StudyLessonsEditor',
  EditStudyLessonItem: 'StudyLessonsEditor/:studyLessonId',
  LearningMaterialsList: 'LearningMaterials',
  LearningMaterialsEditor: 'LearningMaterialsEditor',
  EditLearningMaterialItem: 'LearningMaterialsEditor/:learningMaterialId',
  User: 'User',
  ExcelCalculator: 'ExcelCalculator',
  Translation: 'Translation',
  OrganizationsList: 'Organizations',
  OrganizationsEditor: 'OrganizationsEditor',
  EditOrganizationItem: 'OrganizationsEditor/:organizationId',
  TestInvitations: 'TestInvitations',
  TrackmanParametersList: 'TrackmanParameters',
  TrackmanParametersEditor: 'TrackmanParametersEditor',
  EditTrackmanParameter: 'TrackmanParametersEditor/:parameterId',
} as const;

export const DefaultRoutes = {
  LandingPage: '/',

  Home: '/Home',
  TrackmanParameters: '/TrackmanParameters',
  LearningMaterials: '/LearningMaterials',
  ModulesAndTests: '/ModulesAndTests',
  Certifications: '/Certifications',
  PurchaseMembership: '/PurchaseMembership',
  Organization: '/Organization',
  Admin: '/Admin',
  Profile: '/Profile',
  Feedback: '/Feedback',
  CookiePolicy: '/CookiePolicy',
  TermsAndConditions: '/TermsAndConditions',
  PrivacyPolicy: '/PrivacyPolicy',
  NotFound: '/NotFound',
  NoAccess: '/NoAccess',

  ModulesAndTestsItem: ':courseId/:lessonId',

  ...LearningMaterialsRelativeRoutes,

  ...CertificationsTabsRelativeRoutes,

  ...ProfileTabsRelativeRoutes,

  ...OrganizationTabsRelativeRoutes,

  ...AdminPagesRelativeRoutes,
} as const;

export type DefaultRouteKey = keyof typeof DefaultRoutes;

export const routes = (
  <Routes>
    <Route index element={<LandingPage />} />
    <Route path={DefaultRoutes.LandingPage} element={<LandingPage />} />

    <Route path={DefaultRoutes.Home} element={<HomePage />} />

    <Route path={DefaultRoutes.ModulesAndTests} element={<ModulesAndTests />} />
    <Route path={DefaultRoutes.ModulesAndTests + '/' + DefaultRoutes.ModulesAndTestsItem} element={<ModulesAndTestsItem />} />

    <Route path={DefaultRoutes.TrackmanParameters} element={<TrackmanParameters />} />

    <Route path={DefaultRoutes.LearningMaterials} element={<LearningMaterials />} />
    <Route path={DefaultRoutes.LearningMaterials + '/' + DefaultRoutes.LearningMaterialsItem} element={<LearningMaterialsLessonPage />} />
    <Route path={DefaultRoutes.Certifications} element={<GetCertified />}>
      <Route index element={<AboutTab />} />
      <Route path={DefaultRoutes.Certificate} element={<CertificateTab />} />
      <Route path={DefaultRoutes.AboutTrackmanCertification} element={<AboutTab />} />
      <Route path={DefaultRoutes.ApplyForMaster} element={<ApplyTab />} />
      <Route path={DefaultRoutes.Partners} element={<PartnersTab />} />
      <Route path={DefaultRoutes.Masters} element={<MastersTab />} />
    </Route>
    <Route path={DefaultRoutes.PurchaseMembership} element={<PurchaseMembershipPage />} />
    <Route path={DefaultRoutes.Profile} element={<Profile />}>
      <Route index element={<PlayerInformationTab />} />
      <Route path={DefaultRoutes.PlayerInformation} element={<PlayerInformationTab />} />
      <Route path={DefaultRoutes.PlayerSettings} element={<PlayerSettingsTab />} />
      <Route path={DefaultRoutes.BillingAndPayment} element={<BillingAndPaymentTab />} />
      <Route path={DefaultRoutes.Subscriptions} element={<SubscriptionsTab />} />
    </Route>
    <Route path={DefaultRoutes.Organization} element={<Organization />}>
      <Route index element={<ProgressTab />} />
      <Route path={DefaultRoutes.Progress} element={<ProgressTab />} />
      <Route path={DefaultRoutes.Students} element={<StudentsTab />} />
      <Route path={DefaultRoutes.Classes} element={<ClassesTab />} />
    </Route>
    <Route path={DefaultRoutes.Admin}>
      <Route index element={<CourseList />} />
      <Route path={DefaultRoutes.CourseList} element={<CourseList />} />
      <Route path={DefaultRoutes.CoursesEditor} element={<EditCourse />} />
      <Route path={DefaultRoutes.EditCourseItem} element={<EditCourse />} />
      <Route path={DefaultRoutes.LessonList} element={<LessonList />} />
      <Route path={DefaultRoutes.LessonsEditor} element={<EditLesson />} />
      <Route path={DefaultRoutes.EditLessonItem} element={<EditLesson />} />
      <Route path={DefaultRoutes.OrganizationsList} element={<OrganizationsList />} />
      <Route path={DefaultRoutes.OrganizationsEditor} element={<EditOrganization />} />
      <Route path={DefaultRoutes.EditOrganizationItem} element={<EditOrganization />} />
      <Route path={DefaultRoutes.StudyCoursesList} element={<StudyCoursesList />} />
      <Route path={DefaultRoutes.StudyCoursesEditor} element={<EditStudyCourse />} />
      <Route path={DefaultRoutes.EditStudyCourseItem} element={<EditStudyCourse />} />
      <Route path={DefaultRoutes.StudyLessonsList} element={<StudyLessonsList />} />
      <Route path={DefaultRoutes.StudyLessonsEditor} element={<EditStudyLesson />} />
      <Route path={DefaultRoutes.EditStudyLessonItem} element={<EditStudyLesson />} />
      <Route path={DefaultRoutes.LearningMaterialsList} element={<LearningMaterialsList />} />
      <Route path={DefaultRoutes.LearningMaterialsEditor} element={<EditLearningMaterial />} />
      <Route path={DefaultRoutes.EditLearningMaterialItem} element={<EditLearningMaterial />} />
      <Route path={DefaultRoutes.TrackmanParametersList} element={<TrackmanParametersList />} />
      <Route path={DefaultRoutes.TrackmanParametersEditor} element={<EditTrackmanParameter />} />
      <Route path={DefaultRoutes.EditTrackmanParameter} element={<EditTrackmanParameter />} />
      <Route path={DefaultRoutes.User} element={<ManageUserData />} />
      <Route path={DefaultRoutes.ExcelCalculator} element={<ExcelCalculator />} />
      <Route path={DefaultRoutes.Translation} element={<TranslatorPage />} />
      <Route path={DefaultRoutes.TestInvitations} element={<TestInvitations />} />
    </Route>
    <Route path={DefaultRoutes.Profile} element={<Profile />} />
    <Route path={DefaultRoutes.Feedback} element={<Feedback />} />
    <Route path={DefaultRoutes.CookiePolicy} element={<CookiePolicyPage />} />
    <Route path={DefaultRoutes.TermsAndConditions} element={<TermsAndConditionsPage />} />
    <Route path={DefaultRoutes.PrivacyPolicy} element={<PrivacyPolicyPage />} />
    <Route path={DefaultRoutes.NoAccess} element={<NoAccess />} />
    <Route path={DefaultRoutes.NotFound} element={<NotFound />} />
    <Route path='*' element={<Navigate to={DefaultRoutes.NotFound} />} />
  </Routes>
);
