import { Can, Cannot } from './components/Can';
import { FC, useEffect, useMemo } from 'react';
import { Loader, ThemeProviderProps, ThemeProvider as _ThemeProvider } from '@trackman/web-shared-components';
import { Navigate, useLocation } from 'react-router-dom';

import { AppPermissions } from './constants/AppPermissions';
import { DefaultRoutes } from 'Routes';
import Forbidden from './pages/Forbidden/Forbidden';
import { Notifications } from 'components/Notifications';
import PageLayout from 'components/PageLayout/PageLayout';
import ReactGA from 'react-ga4';
import { i18nInit } from 'i18nSetup';
import { observer } from 'mobx-react-lite';
import { useStores } from 'index';

const ThemeProvider = _ThemeProvider as unknown as FC<ThemeProviderProps & { children?: React.ReactNode }>;

interface IProps {
  children?: React.ReactNode;
}

export const App: FC<IProps> = observer((props) => {
  const stores = useStores();
  const as = stores.authenticationService;
  const ps = stores.permissionsStore;
  const ls = stores.languagesStore;
  const location = useLocation();
  const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

  if (googleAnalyticsId) {
    ReactGA.initialize(googleAnalyticsId);
  } else {
    console.warn('Google Analytics ID is not defined');
  }

  useEffect(() => {
    as.checkIfUserIsLoggedIn();
  }, [as]);

  useEffect(() => {
    as.isUserLoggedIn === true && ps.checkUserPermissions();
  }, [as.isUserLoggedIn, ps]);

  useEffect(() => {
    ls.getUserLanguage();
    if (ls.userLanguage) localStorage.i18nextLng = ls.userLanguage;
    i18nInit();
  }, [ls]);

  useEffect(() => {
    !location.pathname.includes(DefaultRoutes.Admin) && ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, [window.location.pathname]);

  const isLandingPage = useMemo(() => location.pathname === DefaultRoutes.LandingPage, [location.pathname]);
  const noAccessToOrgPage = location.pathname.startsWith(DefaultRoutes.Organization) && ps.userPermissions.organizations.length === 0;
  const noAccessToAdminPage = location.pathname.includes(DefaultRoutes.Admin) && typeof ps.userPermissions.courses === 'string';

  return (
    <ThemeProvider>
      {as.isLoadingUserLoggedIn || (as.isUserLoggedIn && ps.isLoadingUserPermissions) ? (
        <div className='loader-wrapper'>
          <Loader size='large' />
        </div>
      ) : (
        <>
          {as.isUserLoggedIn && isLandingPage && <Navigate to={DefaultRoutes.Home} replace={true} />}
          {as.isUserLoggedIn && noAccessToOrgPage && <Navigate to={DefaultRoutes.NoAccess} replace={true} />}
          {as.isUserLoggedIn && noAccessToAdminPage && <Navigate to={DefaultRoutes.NoAccess} replace={true} />}
          {as.isUserLoggedIn && !isLandingPage && (
            <>
              <Notifications />
              <Can need={AppPermissions.PortalAccess}>
                <PageLayout>{props.children}</PageLayout>
              </Can>
              <Cannot need={AppPermissions.PortalAccess}>
                <Forbidden />
              </Cannot>
            </>
          )}
          {!as.isUserLoggedIn && isLandingPage && <PageLayout>{props.children}</PageLayout>}
          {!as.isUserLoggedIn && !isLandingPage && <Navigate to={DefaultRoutes.LandingPage} replace={true} />}
        </>
      )}
    </ThemeProvider>
  );
});

export default App;
