import '../../../../pages/Admin/Admin.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './EditTrackmanParameter.scss';

import { useEffect, useState } from 'react';

import AdminEditWarning from 'components/Admin/AdminEditWarning/AdminEditWarning';
import JSONPreview from 'components/Admin/JSONPreview/JSONPreview';
import LanguageSettings from 'components/Admin/LanguageSettings/LanguageSettings';
import ParameterAdditionalArticles from 'components/Admin/ParameterSettings/ParameterAdditionalArticles/ParameterAdditionalArticles';
import ParameterCategories from 'components/Admin/ParameterSettings/ParameterCategories/ParameterCategories';
import ParameterDefinition from 'components/Admin/ParameterSettings/ParameterDefinition/ParameterDefinition';
import ParameterLegendMedia from 'components/Admin/ParameterSettings/ParameterMedia/ParameterLegendMedia/ParameterLegendMedia';
import ParameterMedia from 'components/Admin/ParameterSettings/ParameterMedia/ParameterMedia';
import ParameterPrimaryCategory from 'components/Admin/ParameterSettings/ParameterPrimaryCategory/ParameterPrimaryCategory';
import ParameterUsageInfo from 'components/Admin/ParameterSettings/ParameterUsageInfo/ParameterUsageInfo';
import ParameterVideos from 'components/Admin/ParameterSettings/ParameterVideos/ParameterVideos';
import TitleInputs from 'components/Admin/TitleInputs/TitleInputs';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import useSetPageTitle from 'utilities/useSetPageTitle';
import { useStores } from 'index';

export const EditTrackmanParameter = observer(() => {
  useSetPageTitle({ title: 'Edit Trackman Parameter', subtitle: '' });
  const parameterId = useParams().parameterId;
  const ps = useStores().parametersStore;
  const [videoIdError, setVideoIdError] = useState(false);
  const [threeDModelUrlError, setThreeDModelUrlError] = useState(false);

  useEffect(() => {
    if (parameterId) {
      ps.getParameter(parameterId);
    }
    return () => {
      ps.parameter = { ...ps.cleanParameter };
    };
  }, [ps, parameterId]);

  const validateAndSaveParameter = () => {
    const validatedVideoUrls = ps.parameter.videoUrls.filter((video) => video.videoUrl !== '');
    ps.parameter.videoUrls = validatedVideoUrls;

    if (parameterId) {
      ps.saveExistingParameterAndUpdate();
    } else {
      ps.saveNewParameterAndUpdate();
    }
  };

  const disableButton = () =>
    ps.parameter.titles.find((title) => title.countryCode === 'en-US')?.text === '' || videoIdError || threeDModelUrlError;

  return (
    <div className='admin-content-container'>
      <AdminEditWarning />
      <LanguageSettings dataItem={ps.parameter} />
      <TitleInputs dataItem={ps.parameter} />
      <ParameterPrimaryCategory />
      <ParameterCategories />
      <ParameterMedia setThreeDModelUrlError={setThreeDModelUrlError} threeDModelUrlError={threeDModelUrlError} />
      <ParameterLegendMedia />
      <ParameterDefinition />
      <ParameterUsageInfo />
      <ParameterVideos setVideoIdError={setVideoIdError} videoIdError={videoIdError} />
      <ParameterAdditionalArticles />
      <JSONPreview jsonPreview={JSON.stringify(ps.parameter, null, 2)} />
      <div className='admin-footer'>
        <button className='styled-button primary' onClick={() => validateAndSaveParameter()} disabled={disableButton()}>
          Save changes
        </button>
      </div>
    </div>
  );
});

export default EditTrackmanParameter;
