import './ParameterMedia.scss';

import Paragraph from 'components/TextComps/Paragraph';
import ParameterMainImage from './ParameterMainImage/ParameterMainImage';
import { ParameterMediaType } from 'types/admin/trackmanParameters';
import ProfileInput from 'components/ProfileInput/ProfileInput';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useStores } from 'index';

type Props = {
  setThreeDModelUrlError: (value: boolean) => void;
  threeDModelUrlError: boolean;
};

const mediaTypes = [
  { label: 'Image', value: 'Image' },
  { label: '3D Model', value: 'ThreeDModel' },
];

export const ParameterMedia = observer(({ setThreeDModelUrlError, threeDModelUrlError }: Props) => {
  const ps = useStores().parametersStore;

  const onMediaTypeChange = useCallback((mediaType: ParameterMediaType) => {
    ps.parameter.mediaType = mediaType;

    if (mediaType === 'ThreeDModel') {
      delete ps.parameter.mainImgData;
      ps.parameter.threeDModelUrl = '';
      ps.parameter.legendData = [];
    } else {
      delete ps.parameter.threeDModelUrl;
      ps.parameter.legendData = [];
    }
  }, []);

  const validateThreeDModelUrl = useCallback(
    (url: string) => {
      const containsSpline = url.includes('spline');
      setThreeDModelUrlError(!containsSpline);
    },
    [setThreeDModelUrlError]
  );

  return (
    <div className='admin-edit-section'>
      <h2>Parameter media</h2>
      {ps.parameter.mediaType === 'ThreeDModel' && (
        <Paragraph>
          <span className={threeDModelUrlError ? 'error' : ''}>
            Please add only URL-s for models created in <strong>https://spline.design/</strong> service.
          </span>
        </Paragraph>
      )}
      <ProfileInput
        label='Media Type'
        value={ps.parameter.mediaType ?? ''}
        onChange={(newValue) => {
          const selectedMediaType = mediaTypes.find((type) => type.value === newValue);

          if (selectedMediaType) {
            onMediaTypeChange(selectedMediaType.value as ParameterMediaType);
          }
        }}
        selectOptions={mediaTypes.map((mediaType) => {
          return {
            label: mediaType ? mediaType.label : '',
            value: mediaType ? mediaType.value : '',
          };
        })}
      />
      <div className='parameter-media'>
        {ps.parameter.mediaType === 'Image' ? (
          <ParameterMainImage />
        ) : (
          <ProfileInput
            className={threeDModelUrlError ? 'error' : ''}
            label='3D Model URL'
            value={ps.parameter.threeDModelUrl ?? ''}
            onChange={(newValue) => {
              setThreeDModelUrlError(false);
              validateThreeDModelUrl(newValue);
              ps.parameter.threeDModelUrl = newValue;
            }}
          />
        )}
      </div>
    </div>
  );
});

export default ParameterMedia;
