import '../SideMenu.scss';

import { CountryFlag } from 'utilities/CountryFlag';
import { LanguageType } from 'types/languages';
import MenuItem from 'components/MenuItem/MenuItem';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStores } from 'index';

type Props = {
  onClick: (countryCode: LanguageType) => void;
};

export const LanguageSubmenu = observer(({ onClick }: Props) => {
  const ls = useStores().languagesStore;

  useEffect(() => {
    ls.loadLanguages();
  }, [ls]);

  return (
    <div className='submenu-container'>
      <div className='submenu-menu'>
        {ls.languageInfo.map((language) => {
          return (
            <MenuItem
              key={language.countryCode}
              label={language.displayName}
              onClick={() => onClick(language.countryCode)}
              isActive={ls.userLanguage === language.countryCode}
              icon={<CountryFlag countryCode={language.countryCode} />}
              isSubmenu
            />
          );
        })}
      </div>
    </div>
  );
});

export default LanguageSubmenu;
